import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ABTestService } from '@x/common/ab-test/ab-test.service';
import { TFeatureTestType } from '@x/common/ab-test/types';
import { TAssociationOrigin, TrackingService } from '@x/common/tracking';
import { TEImpressionProduct } from '@x/common/tracking/event-models';
import { ChannelContextService } from '@x/ecommerce-shop/app/channel/services/channel-context.service';
import { RegionContextService } from '@x/ecommerce-shop/app/core/region/services/region-context.service';
import { IShopConfig, SHOP_CONFIG } from '@x/ecommerce-shop/config/shop.config';
import {
  IShopCheckoutOrder,
  IShopOrder,
  IShopOrderItem,
  IShopProductItem,
  ShopAssociationsService,
  TShopAssociatedProductsQueryVariables,
  TShopAssociation,
  TShopAssociationWithProducts,
  TShopAssociationsQueryVariables,
} from '@x/ecommerce/shop-client';
import { ProductAssociationTarget } from '@x/schemas/ecommerce';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class AssociationService {
  constructor(
    private regionContextService: RegionContextService,
    @Inject(LOCALE_ID)
    private locale: string,
    private abtestService: ABTestService,
    @Inject(SHOP_CONFIG) private shopConfig: IShopConfig,
    private shopAssociationsService: ShopAssociationsService,
    private channelContext: ChannelContextService,
    private trackingService: TrackingService,
  ) {}

  private testCases$(testType?: TFeatureTestType): Observable<[string] | null> {
    if (!testType) return of(null);

    const feature = this.shopConfig.abTestConfig?.features?.[testType];

    if (!feature) return of(null);

    return this.abtestService
      .observeFeature$(feature.name, { firstValueOnly: feature.options?.firstValueOnly })
      .pipe(
        map((val) => {
          if (!val) return null;

          if (typeof val === 'string' && val.length > 0) {
            return [val];
          }

          return null;
        }),
      );
  }

  getOrderAssociation$(
    order: IShopOrder | IShopCheckoutOrder | null,
    target: ProductAssociationTarget,
    testCases: string[] | null = null,
    productsCount: number = 3,
  ): Observable<TShopAssociationWithProducts | null> {
    if (!order) return of(null);

    if (order.items.length === 0) return of(null);

    return this.regionContextService
      .getFirstAssociationThatHasProducts$({
        locale: this.locale,
        channelCode: order.channelCode,
        target,
        productIds: order.items.map((item) => item.productId),
        page: { size: productsCount, index: 0 },
        testCases,
      })
      .pipe(
        map((association) => {
          return association?.products.length ? association : null;
        }),
        shareReplay(),
        catchError(() => of(null)),
      );
  }

  getAddToBagAssociation$(
    query: {
      order: IShopOrder | null;
      itemAdded: IShopOrderItem;
      productsCount: number;
    },
    testType?: TFeatureTestType,
  ) {
    const { order, itemAdded, productsCount } = query;

    if (!order) return of(null);

    const excludeProductIds = order.items.map((item) => item.productId) ?? [];

    return this.testCases$(testType).pipe(
      switchMap((testCases) => {
        return this.regionContextService
          .getFirstAssociationThatHasProducts$({
            target: ProductAssociationTarget.Cart,
            productIds: itemAdded.productId,
            ...(excludeProductIds.length && { excludeProductIds }),
            locale: this.locale,
            channelCode: order.channelCode,
            page: { size: productsCount, index: 0 },
            testCases,
          })
          .pipe(
            map((association) => {
              return association?.products.length ? association : null;
            }),
            shareReplay(),
            catchError(() => of(null)),
          );
      }),
    );
  }

  getAssociatedProducts$(
    query: TShopAssociatedProductsQueryVariables,
  ): Observable<IShopProductItem[]> {
    return this.regionContextService.fetchProductAssociations(query).pipe(
      map((products) => products),
      catchError(() => of([])),
    );
  }

  getAssociations$(
    query: {
      productIds: number | number[];
      target: ProductAssociationTarget;
      testCases?: string[];
    },
    testType?: TFeatureTestType,
  ): Observable<TShopAssociation[]> {
    if (!this.channelContext.channelCode) return of([]);

    const _query: TShopAssociationsQueryVariables = {
      channelCode: this.channelContext.channelCode,
      locale: this.locale,
      ...query,
    };

    return this.testCases$(testType).pipe(
      switchMap((testCases) => {
        return this.shopAssociationsService.fetchAssociations$({
          ..._query,
          testCases,
        });
      }),
    );
  }

  sendEvent(
    visible: boolean,
    product: IShopProductItem,
    association: TShopAssociationWithProducts,
    associationOrigin: TAssociationOrigin,
  ) {
    if (visible) {
      this.trackingService.sendEvent(
        new TEImpressionProduct(this.trackingService.routeOrigin, {
          product,
          associationId: association.id,
          taxonId: product.mainTaxonId,
          listName: association.title,
          associationOrigin,
        }),
      );
    }
  }
}
