import { Inject, Injectable, Optional } from '@angular/core';
import { Store } from '@ngxs/store';
import { CookieService } from '@x/common/browser';
import { CheckoutOrderState } from '@x/ecommerce-shop/app/checkout/state/checkout-order.state';
import { ShopOrderState } from '@x/ecommerce-shop/app/core/order/state/shop-order.state';
import {
  IShopCollection,
  IShopProductItem,
  IShopProductListingQuery,
  IShopProductSearchQuery,
  IShopProductSearchResult,
  ShopAssociationsService,
  ShopProductSearchService,
  ShopProductService,
  ShopWishlistService,
  TShopAssociatedProductsQueryVariables,
  TShopAssociationWithProducts,
  TShopAssociationsWithProductsQueryVariables,
  TShopWishListItem,
  TShopWishListItemsQuery,
} from '@x/ecommerce/shop-client';
import { Observable, Subject, combineLatest, distinctUntilChanged } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { DEFAULT_REGION_ID, REGION_COOKIE_KEY } from './default-region';

@Injectable({ providedIn: 'root' })
export class RegionContextService {
  private _currentRegionId: number | undefined | null = this.defaultRegionId;
  private stateChanges = new Subject<void>();

  regionIdChange$ = this.stateChanges.pipe(
    startWith(null),
    map(() => this.currentRegionId),
    distinctUntilChanged(),
    shareReplay(),
  );

  set currentRegionId(id: number | undefined | null) {
    if (!id) {
      this.clearCurrentRegion();
      return;
    }

    if (this.currentRegionId === id) return;
    this._currentRegionId = id;
    this.cookieService.set(REGION_COOKIE_KEY, String(id), { expires: 120 });
    console.log('SET REGION', this.currentRegionId);
    this.stateChanges.next();
  }

  get currentRegionId() {
    return this._currentRegionId;
  }

  constructor(
    private store: Store,
    private shopProductService: ShopProductService,
    private shopProductSearchService: ShopProductSearchService,
    private shopWishlistService: ShopWishlistService,
    private shopAssociationsService: ShopAssociationsService,
    private cookieService: CookieService,
    @Inject(DEFAULT_REGION_ID) @Optional() private defaultRegionId?: number | null | undefined,
  ) {
    // set from cookie
    const regionIdFromCookie = cookieService.get(REGION_COOKIE_KEY);
    if (regionIdFromCookie) {
      this.currentRegionId = Number(regionIdFromCookie);
    }

    //update when region changes on order
    this.getRegionIdFromState$().subscribe((regionId) => {
      this.currentRegionId = regionId;
    });
  }

  clearCurrentRegion() {
    this._currentRegionId = null;
    this.cookieService.delete(REGION_COOKIE_KEY);
    this.stateChanges.next();
  }

  fetchProductListing(
    query: IShopProductListingQuery,
  ): Observable<IShopCollection<IShopProductItem>> {
    return this.shopProductService.fetchProductListing({
      ...query,
      ...(this.currentRegionId && { regionId: this.currentRegionId, includeRegion: true }),
    });
  }

  fetchProductSearch(query: IShopProductSearchQuery): Observable<IShopProductSearchResult> {
    return this.shopProductSearchService.fetchProductSearch({
      ...query,
      ...(this.currentRegionId && { regionId: this.currentRegionId, includeRegion: true }),
    });
  }

  fetchMyFavourites(
    query: TShopWishListItemsQuery,
  ): Observable<IShopCollection<TShopWishListItem>> {
    return this.shopWishlistService.getWishListItems({
      ...query,
      ...(this.currentRegionId && { regionId: this.currentRegionId, includeRegion: true }),
    });
  }

  fetchProductAssociations(
    query: TShopAssociatedProductsQueryVariables,
  ): Observable<IShopProductItem[]> {
    return this.shopAssociationsService.fetchAssociatedProducts$({
      ...query,
      ...(this.currentRegionId && { regionId: this.currentRegionId, includeRegion: true }),
    });
  }

  getFirstAssociationThatHasProducts$(
    query: TShopAssociationsWithProductsQueryVariables,
  ): Observable<TShopAssociationWithProducts | undefined> {
    return this.shopAssociationsService
      .fetchAssociationsWithProducts$({
        ...query,
        ...(this.currentRegionId && { regionId: this.currentRegionId, includeRegion: true }),
      })
      .pipe(
        map((associations) => {
          return associations.find((a) => a.products.length);
        }),
      );
  }

  private getRegionIdFromState$() {
    return combineLatest([
      this.store.select(ShopOrderState.regionId),
      this.store.select(CheckoutOrderState.regionId),
    ]).pipe(
      map(([shopRegionId, checkoutRegionId]) => shopRegionId || checkoutRegionId),
      filter(Boolean),
      filter((regionId) => regionId !== this.currentRegionId),
      distinctUntilChanged(),
    );
  }
}
