import {
  IShopAddressInput,
  IShopOrder,
  IShopOrderDeleteItemMutation,
  IShopOrderItem,
  IShopOrderUpdateAddressMutation,
  IShopOrderUpdateCollectionPointMutation,
  IShopOrderUpdateCustomerMutation,
  IShopOrderUpdateItemMutation,
  OmitOrderIdFromInput,
  IShopOrderUpdateItemsMutation,
  TShopAssociationWithProducts,
  TShopOrderItemContext,
} from '@x/ecommerce/shop-client';
import { AddressAssignment } from '@x/schemas/ecommerce';

export class OrderCreateCart {
  static readonly type = '[Order] CreateCart';
  constructor(
    public args?: {
      addOrderItemInput?: OmitOrderIdFromInput<IShopOrderUpdateItemMutation>;
      context?: TShopOrderItemContext | null;
      addressInput?: { address?: IShopAddressInput | null; assignment?: AddressAssignment | null };
      collectionPointId?: number;
    },
  ) {}
}

export class OrderUpdateCartItem {
  static readonly type = '[Order] UpdateCartItem';
  constructor(
    public query: OmitOrderIdFromInput<IShopOrderUpdateItemMutation>,
    public notify: boolean = true,
    public context?: TShopOrderItemContext | null,
  ) {}
}

export class OrderUpdateCartItems {
  static readonly type = '[Order] UpdateCartItems';
  constructor(public query: IShopOrderUpdateItemsMutation['input']) {}
}

export class OrderAddCartItem {
  static readonly type = '[Order] AddCartItem';
  constructor(
    public query: OmitOrderIdFromInput<IShopOrderUpdateItemMutation>,
    public notify: boolean = true,
    public context?: TShopOrderItemContext | null,
  ) {}
}

export class OrderDeleteCartItem {
  static readonly type = '[Order] DeleteCartItem';
  constructor(public query: OmitOrderIdFromInput<IShopOrderDeleteItemMutation>) {}
}

export class OrderUpdateCartAddress {
  static readonly type = '[Order] UpdateCartAddress';
  constructor(public query: OmitOrderIdFromInput<IShopOrderUpdateAddressMutation>) {}
}

export class OrderUpdateCartCustomer {
  static readonly type = '[Order] UpdateCartCustomer';
  constructor(public query: OmitOrderIdFromInput<IShopOrderUpdateCustomerMutation>) {}
}

export class OrderUpdateCartCollectionPoint {
  static readonly type = '[Order] UpdateCartCollectionPoint';
  constructor(public query: OmitOrderIdFromInput<IShopOrderUpdateCollectionPointMutation>) {}
}

export class OrderFetchShopOrder {
  static readonly type = '[Order] FetchShopOrder';
  constructor(public orderId: number) {}
}

export class OrderSetShopOrder {
  static readonly type = '[Order] SetShopOrder';
  constructor(public order: IShopOrder) {}
}

export class OrderClearToken {
  static readonly type = '[Order] ClearToken';
}

export class OrderInit {
  static readonly type = '[Order] OrderInit';
}

export class OrderItemAddedToCartAlert {
  static readonly type = '[Order] OrderItemAddedToCartAlert';
  constructor(
    public orderItem: IShopOrderItem,
    public association?: TShopAssociationWithProducts,
  ) {}
}

export class OrderFetchOrderItemAssociations {
  static readonly type = '[Order] OrderFetchAssociations';
  constructor(public itemAdded: IShopOrderItem) {}
}
