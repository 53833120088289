import { Injectable } from '@angular/core';
import { ChannelContextService } from '@x/ecommerce-shop/app/channel/services/channel-context.service';

@Injectable()
export class UrlService {
  constructor(private channelContextService: ChannelContextService) {}

  private get channelCode() {
    return this.channelContextService.channelCode;
  }

  taxonUrl(taxonId: number, slug: string): string {
    return `/${this.channelCode}/t/${taxonId}/${slug}`;
  }

  productListingUrl(taxonId: number, slug: string): string {
    return `/${this.channelCode}/pl/${taxonId}/${slug}`;
  }

  productDetailUrl(id: number, slug?: string): string {
    const url = `/${this.channelCode}/p/${id}`;
    return slug ? `${url}/${slug}` : `${url}`;
  }
}
