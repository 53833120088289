import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { filter, Subject, takeUntil, tap } from 'rxjs';
import { ShopStorage } from '../../shop-storage/shop-storage.service';
import { IReferrerModuleConfig, REFERRER_MODULE_CONFIG } from '../referrer-module.config';
import { ReferrerClear, ReferrerSet } from './referrer.actions';

export interface ReferrerStateModel {
  referrerCode: string | null;
}

const defaults: ReferrerStateModel = {
  referrerCode: null,
};

@State<ReferrerStateModel>({
  name: 'referrerState',
  defaults,
})
@Injectable()
export class ReferrerState implements OnDestroy, NgxsOnInit {
  private readonly _destroy$ = new Subject<void>();

  @Selector()
  static referrerCode(state: ReferrerStateModel): string | null {
    return state.referrerCode;
  }

  constructor(
    @Inject(REFERRER_MODULE_CONFIG)
    private readonly config: IReferrerModuleConfig,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly storage: ShopStorage,
  ) {}

  @Action(ReferrerSet)
  set({ patchState }: StateContext<ReferrerStateModel>, { referrerCode }: ReferrerSet) {
    this.storage.setItem(this.config.storageKey, referrerCode);
    patchState({ referrerCode });
  }

  @Action(ReferrerClear)
  clear({ patchState }: StateContext<ReferrerStateModel>) {
    this.storage.removeItem(this.config.storageKey);
    return patchState({ ...defaults });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  async ngxsOnInit({ dispatch }: StateContext<any>) {
    const storageReferrer = this.storage.getItem(this.config.storageKey);

    if (typeof storageReferrer === 'string') {
      dispatch(new ReferrerSet(storageReferrer));
    }

    this.router.events
      .pipe(
        takeUntil(this._destroy$),
        filter((event: any) => event instanceof NavigationEnd),
        tap(() => {
          const queryParams = this.route.snapshot.queryParams;
          for (let param of this.config.queryParameters) {
            if (param in queryParams && typeof queryParams[param] === 'string') {
              return dispatch(new ReferrerSet(queryParams[param]));
            }
          }
          return null;
        }),
      )
      .subscribe();
  }
}
