export class ReferrerSet {
  static readonly type = '[Referrer] Set';
  constructor(public referrerCode: string) {}
}
export class ReferrerClear {
  static readonly type = '[Referrer] Clear';
  constructor() {}
}

export class ReferrerUpdateOrder {
  static readonly type = '[Referrer] Update order';
  constructor(public orderId: number, public orderReferrer: string | null | undefined) {}
}
