import { Inject, Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  FeatureDefinition,
  GrowthBook,
  LocalStorageStickyBucketService,
} from '@growthbook/growthbook';
import { WindowRef } from '@x/common/browser';
import Cookies from 'js-cookie';
import {
  Observable,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  merge,
  share,
  switchMap,
} from 'rxjs';
import { startWith, take } from 'rxjs/operators';
import { CookieBotService } from '../cookie-bot/cookie-bot.service';
import { makeId } from '../utils';
import { GROWTH_BOOK_CONFIG, TGBAttributes, TGrowthBookConfig } from './ab-test.config';
import { AppFeatures } from './app-features';
import {
  AB_TEST_DEVICE_ID_COOKIE_NAME,
  AB_TEST_FEATURE_FLAVOR_SELECTOR,
  AB_TEST_INJECTED_ELEMENT_ID,
} from './constants';
import { AppFeatureSelector, FeatureNameFromFlavorSelector, FeatureSelectorType } from './types';

export interface IFeatureOptions {
  firstValueOnly?: boolean;
}

export interface IFeatureEnabledOptions extends IFeatureOptions {
  showByDefault?: boolean;
  allowNull?: boolean;
}

@Injectable()
export class ABTestService {
  private subscription$ = new Subscription();
  private isInitialised = false;
  private deviceId: string | undefined;
  private growthBook: GrowthBook<AppFeatures>;
  private featuresUpdated$ = merge(
    // When GB features change, wait for a page change to emit a value
    new Observable<void>((subscriber) => {
      this.growthBook?.setRenderer(() => {
        subscriber.next(); // Emit to signal the subscribers
      });
    }).pipe(
      switchMap(() =>
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1),
        ),
      ),
    ),
    // Update features immediately when the cookie consent state changes
    this.cookieBotService.cookieConsent$,
  ).pipe(
    filter(() => !!this.growthBook),
    share(),
  );

  constructor(
    private readonly router: Router,
    @Inject(GROWTH_BOOK_CONFIG)
    private readonly config: TGrowthBookConfig,
    private readonly cookieBotService: CookieBotService,
    private windowRef: WindowRef,
  ) {
    this.subscription$.add(
      cookieBotService.cookieConsent$.subscribe(() => {
        // getDeviceId will persist the device ID if needed
        this.getDeviceId();
      }),
    );
  }

  observeFeature$<Feature extends AppFeatureSelector>(
    featureName: Feature,
    options?: IFeatureOptions,
  ): Observable<FeatureSelectorType<Feature> | null> {
    const featureValue$ = this.featuresUpdated$.pipe(
      filter(() => !!this.growthBook),
      map(() => this.evalFeature(featureName)),
      startWith(this.evalFeature(featureName)),
      distinctUntilChanged(),
    );

    return options?.firstValueOnly ? featureValue$.pipe(take(1)) : featureValue$;
  }

  observeFeatureEnabled$<Feature extends AppFeatureSelector>(
    featureName: Feature,
    value?: any,
    options?: IFeatureEnabledOptions,
  ): Observable<boolean> {
    return this.observeFeature$(featureName, options).pipe(
      map((response) => {
        if (response != null) return value != null ? response === value : !!response;
        return Boolean(options?.showByDefault);
      }),
      distinctUntilChanged(),
    );
  }

  async init(config: TGrowthBookConfig) {
    if (this.isInitialised) return;

    let features = this.getSSRInjectedFeatures();

    /*init only on localhost*/
    if (isDevMode()) {
      this.growthBook = this.createGBInstance(config);
      await this.growthBook.loadFeatures();
      features = this.growthBook.getFeatures();
    }

    /**
     * Unless isDevMode, If the features haven't been SSR injected then we shouldn't use GrowthBook.
     * Loading the features later may create an inconsistent experience which could bias the experiment results,
     * not loading the features and not firing any experiment events is probably preferable in this case. This
     * will also make it more obvious if something breaks with the injection so it will help to avoid silently
     * causing issues.
     */

    if (features == null) return;

    if (!this.growthBook) this.growthBook = this.createGBInstance(config, features);

    this.isInitialised = true;

    console.log('GrowthBook initialised with features:', features);

    this.updateAttributes(config.attributesToObserve);
  }

  private createGBInstance(
    config: TGrowthBookConfig,
    features?: Record<string, FeatureDefinition>,
  ) {
    const { apiHost, clientKey, onExperimentViewed, enableDevMode } = config;

    return new GrowthBook<AppFeatures>({
      apiHost,
      clientKey,
      ...(features && { features }),
      trackingCallback: (experiment, result) => onExperimentViewed(experiment.key, result.key),
      attributes: {
        deviceId: this.getDeviceId(),
      },
      subscribeToChanges: true,
      backgroundSync: true,
      enableDevMode,
      stickyBucketService: new LocalStorageStickyBucketService(),
      stickyBucketIdentifierAttributes: ['userId', 'deviceId'],
    });
  }

  private updateAttributes(attributes: TGBAttributes) {
    this.subscription$.add(
      combineLatest(
        Object.entries(attributes).map(([attributeName, value$]) =>
          value$.pipe(map((value) => [attributeName, value] as const)),
        ),
      )
        .pipe(filter(() => !!this.growthBook))
        .subscribe((attributes) =>
          this.growthBook.setAttributes({
            ...this.growthBook.getAttributes(),
            ...Object.fromEntries(attributes),
          }),
        ),
    );
  }

  private evalFeature<Feature extends AppFeatureSelector>(
    name: Feature,
  ): FeatureSelectorType<Feature> | null {
    if (!this.cookieBotService.hasConsentedToCookies()) {
      return null;
    }

    const featureName = name.replace(
      AB_TEST_FEATURE_FLAVOR_SELECTOR,
      this.config.flavor,
    ) as FeatureNameFromFlavorSelector<Feature>;

    return this.growthBook?.evalFeature(featureName).value;
  }

  private getSSRInjectedFeatures() {
    const rawFeatures = this.windowRef.document?.getElementById(
      AB_TEST_INJECTED_ELEMENT_ID,
    )?.textContent;

    if (rawFeatures == null) {
      return null;
    }

    try {
      return JSON.parse(rawFeatures) as Record<string, FeatureDefinition>;
    } catch {
      return null;
    }
  }

  private persistDeviceId() {
    if (!this.cookieBotService.hasConsentedToCookies() || !this.deviceId) {
      return;
    }

    Cookies.set(AB_TEST_DEVICE_ID_COOKIE_NAME, this.deviceId, {
      path: '/',
      expires: 365,
    });
  }

  getDeviceId() {
    const cookie = Cookies.get(AB_TEST_DEVICE_ID_COOKIE_NAME);
    this.deviceId = this.deviceId ?? cookie ?? makeId(32);

    if (this.deviceId !== cookie) {
      this.persistDeviceId();
    }

    return this.deviceId;
  }

  destroy() {
    this.growthBook?.destroy();
    this.subscription$?.unsubscribe();
  }
}
