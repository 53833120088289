import { InjectionToken } from '@angular/core';
import { IFeatureIf, TFeatureTestType } from '@x/common/ab-test/types';
import { Observable } from 'rxjs';

export type TGBAttributes = {
  [key: string]: Observable<string | number | undefined | null>;
};

export const GROWTH_BOOK_CONFIG = new InjectionToken<TGrowthBookConfig>('GROWTH_BOOK_CONFIG');

export interface TABTestConfig {
  apiHost?: string;
  clientKey?: string;
  enableDevMode?: boolean;
  features?: Partial<Record<TFeatureTestType, IFeatureIf>>;
}

export interface TGrowthBookConfig extends TABTestConfig {
  attributesToObserve: TGBAttributes;
  onExperimentViewed: (experimentId: string, variationId: string) => void;
  flavor: 'bt' | 'tn';
}
