import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { OperationObserverService } from '@x/common/operation';
import { TECartRemoveItem, TECartUpdateItem } from '@x/common/tracking/event-models/cart.events';
import { TrackingService } from '@x/common/tracking/tracking.service';
import { ChannelContextService } from '@x/ecommerce-shop/app/channel/services/channel-context.service';
import { ChannelSetActive } from '@x/ecommerce-shop/app/channel/state';
import { CheckoutClearToken } from '@x/ecommerce-shop/app/checkout/state/checkout-order.actions';
import { AssociationService } from '@x/ecommerce-shop/app/core/association/services/association.service';
import { AuthSetUser } from '@x/ecommerce-shop/app/core/auth/state/auth.actions';
import { AuthState } from '@x/ecommerce-shop/app/core/auth/state/auth.state';
import {
  OrderAddCartItem,
  OrderClearToken,
  OrderCreateCart,
  OrderDeleteCartItem,
  OrderFetchOrderItemAssociations,
  OrderFetchShopOrder,
  OrderInit,
  OrderItemAddedToCartAlert,
  OrderSetShopOrder,
  OrderUpdateCartAddress,
  OrderUpdateCartCollectionPoint,
  OrderUpdateCartCustomer,
  OrderUpdateCartItem,
  OrderUpdateCartItems,
} from '@x/ecommerce-shop/app/core/order/state/shop-order.actions';
import { ReferrerSet } from '@x/ecommerce-shop/app/core/referrer/state/referrer.actions';
import { ReferrerState } from '@x/ecommerce-shop/app/core/referrer/state/referrer.state';
import { RegionContextService } from '@x/ecommerce-shop/app/core/region/services/region-context.service';
import { ShopStorage } from '@x/ecommerce-shop/app/core/shop-storage/shop-storage.service';
import {
  IShopAddress,
  IShopGeoRegionDetail,
  IShopOrder,
  IShopOrderCreateMutation,
  IShopOrderItem,
  ShopOrderService,
} from '@x/ecommerce/shop-client';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

export interface OrderStateModel {
  order: IShopOrder | null;
}

const defaults: OrderStateModel = {
  order: null,
};

@State<OrderStateModel>({
  name: 'orderState',
  defaults,
})
@Injectable()
export class ShopOrderState implements NgxsOnInit {
  private actionsQ = this.operations.createQueue();

  constructor(
    private shopOrderService: ShopOrderService,
    private store: Store,
    private shopStorage: ShopStorage,
    @Inject(LOCALE_ID) private locale: string,
    private operations: OperationObserverService,
    private channelContext: ChannelContextService,
    private associationService: AssociationService,
    private trackingService: TrackingService,
    private regionContextService: RegionContextService,
  ) {}

  @Selector()
  static order(state: OrderStateModel): IShopOrder | null {
    return state.order;
  }

  @Selector()
  static orderItems(state: OrderStateModel): IShopOrderItem[] {
    return state.order?.items.filter((i) => i.source !== 'SHIPPING') ?? [];
  }

  @Selector()
  static orderShippingAddress(state: OrderStateModel): IShopAddress | null | undefined {
    return state.order?.shippingAddress;
  }

  @Selector()
  static regionId(state: OrderStateModel): number | undefined {
    return state.order?.shippingAddressRegion?.id;
  }

  @Selector()
  static orderItemCount(state: OrderStateModel): string {
    if (!state.order) return '0';

    const count = state.order.items.reduce((acc, item) => {
      return item.source !== 'SHIPPING' ? acc + item.quantity : acc;
    }, 0);

    return `${count}`;
  }

  @Selector()
  static orderShippingAddressRegion(
    state: OrderStateModel,
  ): IShopGeoRegionDetail | undefined | null {
    return state.order?.shippingAddressRegion;
  }

  @Action(OrderCreateCart)
  createCart({ dispatch }: StateContext<OrderStateModel>, { args }: OrderCreateCart) {
    const channelCode = this.channelContext.channelCode;
    const userId = this.store.selectSnapshot(AuthState.loggedInUser)?.id;
    const referrerCode = this.store.selectSnapshot(ReferrerState.referrerCode);

    if (!channelCode) {
      console.log('No Channel when creating order');
      return dispatch(new OrderClearToken());
    }

    const createCartInput: IShopOrderCreateMutation = {
      input: {
        locale: this.locale,
        channelCode,
        referrerCode,
        ...(userId && { userId }),
        ...(args?.addressInput && {
          address: args.addressInput.address,
          addressAssignment: args.addressInput.assignment,
        }),
      },
    };

    const createCart$ = this.shopOrderService.createCart(createCartInput).pipe(
      tap((cart) => (this.shopStorage.orderToken = cart.auth)),
      map(({ order }) => order),
      shareReplay(),
    );

    return createCart$.pipe(
      switchMap((order) => {
        let actions: any[] = [];

        if (args?.addOrderItemInput) {
          actions.push(new OrderUpdateCartItem(args.addOrderItemInput, true, args.context));
        }

        if (args?.collectionPointId) {
          actions.push(
            new OrderUpdateCartCollectionPoint({ collectionPointId: args.collectionPointId }),
          );
        }

        actions.push(new OrderSetShopOrder(order));

        return dispatch(actions);
      }),
    );
  }

  @Action(OrderFetchShopOrder)
  fetchOrder({ dispatch }: StateContext<OrderStateModel>, { orderId }: OrderFetchShopOrder) {
    const determineActionBasedOnOrderState = (order: IShopOrder): Observable<IShopOrder | void> => {
      if (order.state === 'QUOTED') {
        dispatch(new OrderClearToken());
        return dispatch(new Navigate([`/checkout/${order.id}`]));
      }

      if (order.state !== 'CART') {
        return dispatch(new OrderClearToken());
      }

      return of(order);
    };

    const fetchOrder$ = (orderId: number) => {
      return this.shopOrderService.fetchCart(orderId).pipe(
        switchMap((order) => determineActionBasedOnOrderState(order)),
        map((order) => order ?? undefined),
        shareReplay(),
      );
    };

    return fetchOrder$(orderId).pipe(
      switchMap((order) => {
        if (!order) {
          console.log('Cloud not fetch order');
          return dispatch(new OrderClearToken());
        }
        return dispatch(new OrderSetShopOrder(order));
      }),
    );
  }

  @Action(OrderSetShopOrder)
  setOrder({ patchState }: StateContext<OrderStateModel>, { order }: OrderSetShopOrder) {
    this.regionContextService.currentRegionId = order.shippingAddressRegion?.id;

    patchState({ order });
  }

  @Action(OrderAddCartItem)
  addCartItem(
    { dispatch }: StateContext<OrderStateModel>,
    { query, notify, context }: OrderAddCartItem,
  ) {
    const { productVariantId, quantity } = query;

    return this.actionsQ.queue(
      of('start').pipe(
        map(() => this.shopStorage.orderToken?.orderId),
        switchMap((orderId) => {
          if (!orderId) return dispatch(new OrderCreateCart({ addOrderItemInput: query, context }));

          if (quantity === 0) return dispatch(new OrderDeleteCartItem({ productVariantId }));

          return dispatch(new OrderUpdateCartItem(query, notify, context));
        }),
      ),
    );
  }

  @Action(OrderUpdateCartItem)
  updateCartItem(
    { dispatch, getState }: StateContext<OrderStateModel>,
    { query, notify, context }: OrderUpdateCartItem,
  ) {
    const orderId = this.getOrderIdFromStorage(dispatch);

    if (!orderId) return;

    const { productVariantId } = query;

    const orderItemContext = getState().order?.items.find(
      (i) => i.variantId === productVariantId,
    )?.context;

    return this.shopOrderService
      .updateCartItem({
        input: {
          ...query,
          ...(!orderItemContext && { context }),
          orderId,
        },
      })
      .pipe(
        tap((order) => {
          const orderItemAdded = order.items.find((i) => i.variantId === productVariantId);
          if (!orderItemAdded) return;

          this.trackingService.sendEvent(
            new TECartUpdateItem(this.trackingService.routeOrigin, {
              items: [orderItemAdded],
              currency: order.currency,
            }),
          );
        }),
        switchMap((order) => dispatch(new OrderSetShopOrder(order))),
        switchMap(() => {
          if (!notify) return of('complete');

          const { order } = getState();

          const orderItemAdded = order?.items.find((i) => i.variantId === productVariantId);
          if (!orderItemAdded) return of('complete');

          return dispatch(new OrderFetchOrderItemAssociations(orderItemAdded));
        }),
      );
  }

  @Action(OrderUpdateCartItems)
  updateCartItems({ dispatch }: StateContext<OrderStateModel>, { query }: OrderUpdateCartItems) {
    const { items: queryItems } = query;

    return this.shopOrderService.updateCartItems({ input: query }).pipe(
      tap(({ items, currency }) => {
        const itemsAdded = items.filter((item) => {
          return queryItems.some((i) => i.productVariantId === item.variantId);
        });

        this.trackingService.sendEvent(
          new TECartUpdateItem(this.trackingService.routeOrigin, {
            items: itemsAdded,
            currency: currency,
          }),
        );
      }),
      switchMap((order) => dispatch(new OrderSetShopOrder(order))),
    );
  }

  @Action(OrderDeleteCartItem)
  deleteCartItem(state: StateContext<OrderStateModel>, { query }: OrderDeleteCartItem) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    const { productVariantId } = query;
    const itemToRemoveInCurrentState = state
      .getState()
      .order?.items.find((i) => i.variantId === productVariantId);

    return this.shopOrderService.deleteCartItem({ input: { ...query, orderId } }).pipe(
      tap(() => {
        if (!itemToRemoveInCurrentState) return;

        this.trackingService.sendEvent(
          new TECartRemoveItem(this.trackingService.routeOrigin, {
            items: [itemToRemoveInCurrentState],
          }),
        );
      }),
      switchMap((order) => state.dispatch(new OrderSetShopOrder(order))),
    );
  }

  @Action(OrderUpdateCartAddress)
  updateCartAddress(
    { dispatch }: StateContext<OrderStateModel>,
    { query }: OrderUpdateCartAddress,
  ) {
    const orderId = this.getOrderIdFromStorage(dispatch);

    if (!orderId) {
      const { address, assignment } = query;
      if (!address) return;

      return dispatch(new OrderCreateCart({ addressInput: { address, assignment } }));
    }

    return this.shopOrderService
      .updateCartAddress({ input: { ...query, orderId } })
      .pipe(switchMap((order) => dispatch(new OrderSetShopOrder(order))));
  }

  @Action(OrderUpdateCartCustomer)
  orderUpdateCartCustomer(
    state: StateContext<OrderStateModel>,
    { query }: OrderUpdateCartCustomer,
  ) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    const { order } = state.getState();
    if (!order) return;

    const { userId: currentUserId } = order;
    const { userId: userIdToAssign } = query;
    if (userIdToAssign === currentUserId) return;

    if (currentUserId && userIdToAssign !== currentUserId) {
      this.consoleLog('different user assigned to current order, clear shop order state');
      return state.dispatch(new OrderClearToken());
    }

    return this.shopOrderService
      .updateCartCustomer({ input: { userId: userIdToAssign, orderId } })
      .pipe(switchMap((order) => state.dispatch(new OrderSetShopOrder(order))));
  }

  @Action(OrderUpdateCartCollectionPoint)
  updateCartCollectionPoint(
    { dispatch }: StateContext<OrderStateModel>,
    { query }: OrderUpdateCartCollectionPoint,
  ) {
    const orderId = this.getOrderIdFromStorage(dispatch);

    if (!orderId) {
      const { collectionPointId } = query;
      return dispatch(new OrderCreateCart({ collectionPointId }));
    }

    return this.shopOrderService
      .updateCartCollectionPoint({ input: { ...query, orderId } })
      .pipe(switchMap((order) => dispatch(new OrderSetShopOrder(order))));
  }

  @Action(CheckoutClearToken)
  @Action(OrderClearToken)
  clearToken({ setState }: StateContext<OrderStateModel>) {
    this.shopStorage.orderToken = null;
    this.regionContextService.clearCurrentRegion();
    setState({ ...defaults });
  }

  /*assign authed user */
  @Action(AuthSetUser)
  onAuthSetUser({ dispatch }: StateContext<OrderStateModel>, { user }: AuthSetUser) {
    return dispatch(new OrderUpdateCartCustomer({ userId: user.id }));
  }

  /*
   *  Init order state everytime channel is set
   * */
  @Action(ChannelSetActive)
  onChannelSetActive({ dispatch }: StateContext<OrderStateModel>) {
    return dispatch(new OrderInit());
  }

  @Action(ReferrerSet)
  setReferrer(state: StateContext<OrderStateModel>, { referrerCode }: ReferrerSet) {
    const orderId = this.getOrderIdFromStorage(state.dispatch);
    if (!orderId) return;

    return this.shopOrderService
      .updateReferrer({ input: { orderId, referrerCode } })
      .pipe(switchMap(() => state.dispatch(new OrderFetchShopOrder(orderId))));
  }

  @Action(OrderInit)
  init({ dispatch }: StateContext<OrderStateModel>) {
    const orderId = this.getOrderIdFromStorage(dispatch);
    if (!orderId) return;

    const refreshToken$ = this.shopOrderService.refreshOrderToken().pipe(
      tap((token) => (this.shopStorage.orderToken = token)),
      shareReplay(),
    );

    return refreshToken$.pipe(
      switchMap((token) => dispatch(new OrderFetchShopOrder(token.orderId))),
      catchError(() => {
        this.consoleLog('Could not init orderstate');
        return dispatch(new OrderClearToken());
      }),
    );
  }

  @Action(OrderFetchOrderItemAssociations, { cancelUncompleted: true })
  orderItemAddedToCartAlert(
    state: StateContext<OrderStateModel>,
    { itemAdded }: OrderFetchOrderItemAssociations,
  ) {
    const associationWithProducts$ = this.associationService.getAddToBagAssociation$(
      {
        order: state.getState().order,
        itemAdded,
        productsCount: 1,
      },
      'associations-source',
    );

    return associationWithProducts$.pipe(
      switchMap((associationWithProducts) => {
        return state.dispatch(
          new OrderItemAddedToCartAlert(
            itemAdded,
            associationWithProducts ? associationWithProducts : undefined,
          ),
        );
      }),
      catchError(() => {
        return state.dispatch(new OrderItemAddedToCartAlert(itemAdded));
      }),
    );
  }

  ngxsOnInit() {
    this.actionsQ.observe().subscribe();
  }

  private getOrderIdFromStorage(dispatch: Function): number | undefined {
    const orderId = this.shopStorage.orderToken?.orderId;
    if (!orderId) {
      this.consoleLog('No order token in storage');
      dispatch(new OrderClearToken());
      return;
    }
    return orderId;
  }

  private consoleLog(string: string) {
    console.info(`%cShopOrderState: ${string}`, 'color: DodgerBlue');
  }
}
